import { useState, useEffect, useRef } from "react";
import React, { createContext, useContext } from "react";

export const SectionsRefsContext = React.createContext();

export const  SectionsRefsProvider =({children}) => {
  const startseiteRef = useRef(null);
  const ueberMichRef = useRef(null);
  const demosRef = useRef(null);
  const studioRef = useRef(null);
  const kontaktRef = useRef(null);
  const [allSectionsRendered, setAllSectionsRendered] = useState(false);

  useEffect(() => {
    let intervalId; // Define intervalId here

    const checkRefs = () => {
 
      if (startseiteRef.current && ueberMichRef.current && demosRef.current && studioRef.current && kontaktRef.current) {
        setAllSectionsRendered(true);
        clearInterval(intervalId); // Clear the interval once all refs are set
      }
    };

    // Check immediately
    checkRefs();

    // Set an interval to check every 100ms
    intervalId = setInterval(checkRefs, 100);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <SectionsRefsContext.Provider
      value={{
        startseiteRef,
        ueberMichRef,
        demosRef,
        studioRef,
        kontaktRef,
        allSectionsRendered,
      }}
    >
      {children}
    </SectionsRefsContext.Provider>
  );
};

 const useSectionsRefs = () => {
  const context = useContext(SectionsRefsContext);

  if (!context) {
    throw new Error(
      "useSectionsRefs must be used within a SectionsRefsProvider"
    );
  }

  return context;
};
export default useSectionsRefs;