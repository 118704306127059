import React, { Suspense, Component } from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
///
import { getFooterSize } from "./helpers/displayHandler.ts";
///
import CookieConsentComponent from "./components/cookieConsent/CookieConsentComponent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
///
import { ButtonToTop } from "./components/general/generalButtonToTop";
import LoaderCP from "./components/general/generalLoader";
import "./App.css";
import Footer from "./components/general/generalFooter.jsx";
import DesktopPopoverNavigation from "./components/general/generalDesktopPopoverNavigation";
import { SectionsRefsProvider } from "./components/general/generalRefs.jsx";


const HomePage = React.lazy(() =>
  import("./pages/home/home.jsx").then((module) => ({
    default: module.HomePage,
  }))
);

const DataSecurityPage = React.lazy(() =>
  import("./pages/misc/dataSecurity/dataSecurity.jsx").then((module) => ({
    default: module.DataSecurityPage,
  }))
);

const ImpressumPage = React.lazy(() =>
  import("./pages/misc/impressum/impressum.jsx").then((module) => ({

  default: module.ImpressumPage,
  }))
);

export class App extends Component {
  alertOrientation() {
    this.setState({ footerHeight: getFooterSize() });
  }

  toggleMenuBar() {
    this.setState((state) => ({ menuBarOpen: !state.menuBarOpen }));
  }
  render() {
    return (
      <div className="App" id="main">
        <div style={{ height: "100%" }}>
          <ButtonToTop />
          <SectionsRefsProvider>
            <DesktopPopoverNavigation />

            <main id="page-wrap" style={{ minHeight: "100vh" }}>
              <CookieConsentComponent />

              <Suspense fallback={<LoaderCP />}>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route
                    path="/datenschutz"
                    element={
                      <>
                        <DataSecurityPage />
                      </>
                    }
                  />
                  <Route
                    path="/impressum"
                    element={
                      <>
                        <ImpressumPage />
                      </>
                    }
                  />
                </Routes>
              </Suspense>
              <Footer />
            </main>
          </SectionsRefsProvider>
        </div>
      </div>
    );
  }
}

export default App;
