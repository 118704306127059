import { NavLink } from "react-router-dom";
import "../../styling/header.css";
import { Desktop } from "../../helpers/displayHandler";
import { useEffect, useRef, useState } from "react";
import useSectionsRefs from "./generalRefs";
import { activeSelectionSignal } from "./generalDesktopNavigation";

import greyLogo from "../../assets/logo/favIcon_grey.svg";
import blackLogo from "../../assets/logo/favIcon.svg";

function DesktopPopoverNavigation() {
  return (
    <>
      <Desktop>
        <DeskView />
      </Desktop>
    </>
  );
}

function DeskView() {
  const [activeSection, setActiveSection] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const [stopSectionSelection, setStopSectionSelection] = useState(false);
  const { allSectionsRendered } = useSectionsRefs();

  const stopSectionSelectionRef = useRef(stopSectionSelection);

  useEffect(() => {
    // Define a reaction function that will update the local state when the signal changes
    const onSignalChange = () => {
      setStopSectionSelection(true);
      setActiveSection(activeSelectionSignal.value);
      setTimeout(() => {
        setStopSectionSelection(false);
      }, 2500);
    };

    // Subscribe to the signal
    const unsubscribe = activeSelectionSignal.subscribe(onSignalChange);

    // Unsubscribe when the component unmounts or the signal changes
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    stopSectionSelectionRef.current = stopSectionSelection;
    // Define a callback for the observer
    if (!allSectionsRendered) return;
    const callback = (entries) => {
      if (stopSectionSelection) return;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (activeSection === entry.target.id) {
            setActiveSection(entry.target.id);
            setTimeout(() => {
              setStopSectionSelection(false);
            }, 2000);
            return;
          }
          if (stopSectionSelectionRef.current) {
            return;
          } else {
            setActiveSection(entry.target.id);
            setTimeout(() => {
              setStopSectionSelection(false);
            }, 2000);
          }
        }
      });
    };

    // Create the observer with the callback
    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // 50% of the target visible
    });

    // Start observing each section
    ["startseite", "ueberMich", "demos", "studio", "kontakt"].forEach((id) => {
      const el = document.getElementById(id);
      if (el) {
        observer.observe(el);
      }
    });

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      if (scrollY > windowHeight * 0.4) {
        setOpacity(1);
      } else {
        setOpacity(0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the observer on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      ["startseite", "ueberMich", "demos", "studio", "kontakt"].forEach(
        (id) => {
          const el = document.getElementById(id);
          if (el) {
            observer.unobserve(el);
          }
        }
      );
    };
  }, [allSectionsRendered, stopSectionSelection]);

  const scrollTo = (id) => {
    setActiveSection(id);
    setStopSectionSelection(true);

    const element = document.getElementById(id);
    const yOffset = id === "kontakt" ? 0 : id === "demos" ? -84 : -84;
    const block =
      id === "startseite" ? "start" : id === "demos" ? "start" : "center";
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth", block: block });
  };

  return (
    <div className={"my-wideScreenWrapper " + ("safeAreaInset" || "")}>
      <header
        className="headerLink-D-Popover-Wrapper"
        id="popoverHeader"
        style={{
          opacity: opacity,
          pointerEvents: opacity === 0 ? "none" : "unset",
        }}
      >
        <ul>
          <li>
            <NavLink
              draggable={false}
              className={`headerLink-D ${
                activeSection === "startseite" ? "select" : ""
              }`}
              onClick={() => {
                scrollTo("startseite");
              }}
            >
              <img
                src={blackLogo}
                className="me-3 icon"
                style={{ height: "30px"}}
              />
              Startseite
            </NavLink>
          </li>

          <li>
            <NavLink
              draggable={false}
              className={`headerLink-D ${
                activeSection === "demos" ? "select" : ""
              }`}
              onClick={() => {
                scrollTo("demos");
              }}
            >
              Samples
            </NavLink>
          </li>
          <li>
            <NavLink
              draggable={false}
              className={`headerLink-D ${
                activeSection === "ueberMich" ? "select" : ""
              }`}
              onClick={() => {
                scrollTo("ueberMich");
              }}
            >
              Über mich
            </NavLink>
          </li>
          <li>
            <NavLink
              draggable={false}
              className={`headerLink-D ${
                activeSection === "studio" ? "select" : ""
              }`}
              onClick={() => {
                scrollTo("studio");
              }}
            >
              Studio
            </NavLink>
          </li>
          <li>
            <NavLink
              draggable={false}
              className={`headerLink-D ${
                activeSection === "kontakt" ? "select" : ""
              }`}
              onClick={() => {
                scrollTo("kontakt");
              }}
            >
              Kontakt
            </NavLink>
          </li>
        </ul>
      </header>
    </div>
  );
}

export default DesktopPopoverNavigation;
