import { NavLink } from "react-router-dom";
import "../../styling/header.css";
import { Desktop, InBetweenDandW, Widescreen, getDisplaySize } from "../../helpers/displayHandler";
import { useEffect, useState } from "react";
import useSectionsRefs from "./generalRefs";
import { signal } from "@preact/signals-react";
import whiteLogo from "../../assets/logo/favIcon_white.svg";

export const activeSelectionSignal = signal("startseite");

function DesktopNavigation() {
  return (
    <>
      <Desktop>
        <DeskView />
      </Desktop>
    </>
  );
}

function DeskView(props) {
  const [activeSection, setActiveSection] = useState("startseite");
  const { startseiteRef } = useSectionsRefs();
  useEffect(() => {
    // Define a callback for the observer
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // setActiveSection(entry.target.id);
        } else {
          if (activeSection === entry.target.id) {
            // setActiveSection(null);
          }
        }
      });
    };

    // Create the observer with the callback
    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // 50% of the target visible
    });

    // Start observing each section
    ["startseite", "ueberMich", "demos", "studio", "kontakt"].forEach((id) => {
      const el = document.getElementById(id);
      if (el) {
        observer.observe(el);
      }
    });

    // Clean up the observer on unmount
    return () => {
      ["startseite", "ueberMich", "demos", "studio", "kontakt"].forEach(
        (id) => {
          const el = document.getElementById(id);
          if (el) {
            observer.unobserve(el);
          }
        }
      );
    };
  }, [startseiteRef]);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    const yOffset = id === "kontakt" ? 0 : -86;
    const block = id === "startseite" ? "start" : "center";
    activeSelectionSignal.value = id;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth", block: block });
  };
  return (
    <header className="headerLink-D-Wrapper">
      <ul>
        <li>
          <NavLink
            draggable={false}
            className={`headerLink-D ${
              activeSection === "startseite" ? "select" : ""
            }`}
            onClick={() => {
              scrollTo("startseite");
            }}
          >
            <img src={whiteLogo} className="me-3" style={{ height: "30px" }} />
            Startseite
          </NavLink>
        </li>

        <li>
          <NavLink
            draggable={false}
            className={`headerLink-D ${
              activeSection === "demos" ? "select" : ""
            }`}
            onClick={() => {
              scrollTo("demos");
            }}
          >
            Samples
          </NavLink>
        </li>
        <li>
          <NavLink
            draggable={false}
            className={`headerLink-D ${
              activeSection === "ueberMich" ? "select" : ""
            }`}
            onClick={() => {
              scrollTo("ueberMich");
            }}
          >
            Über mich
          </NavLink>
        </li>
        <li>
          <NavLink
            draggable={false}
            className={`headerLink-D ${
              activeSection === "studio" ? "select" : ""
            }`}
            onClick={() => {
              scrollTo("studio");
            }}
          >
            Studio
          </NavLink>
        </li>
        <InBetweenDandW>
          <li>
            <NavLink
              draggable={false}
              className={`headerLink-D ${
                activeSection === "kontakt" ? "select" : ""
              }`}
              onClick={() => {
                scrollTo("kontakt");
              }}
            >
              Kontakt
            </NavLink>
          </li>
        </InBetweenDandW>
      </ul>
    </header>
  );
}

export default DesktopNavigation;
