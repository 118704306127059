/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Reveal } from "react-text-reveal";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import "../../styling/footer.css";
import "../../styling/textWrapper.css";

import greyLogo from "../../assets/logo/favIcon_grey.svg"

function Footer() {
  const [reveal, setReveal] = useState(false);
  const [atHome, setAtHome] = useState(false);
  const year = new Date().getFullYear();

  useEffect(() => {
    if (window.location.pathname === "/") {
      setAtHome(true);
    }

    if (true) {
      setTimeout(() => {
        setReveal(true);
      }, 200);
    }
  }, [setReveal]);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    const yOffset = id === "kontakt" ? 0 : id === "demos" ? -80 : -80;
    const block =
      id === "startseite" ? "start" : id === "demos" ? "start" : "center";
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth", block: block });
  };

  return (
    <Reveal
      canPlay={reveal}
      offset={"35PT"}
      animateOpacity={true}
      delay={300}
      duration={700}
      ease={"cubic-bezier(0,0.4,0.4,1)"}
    >
      <MDBFooter
        bgColor="light"
        className="text-center text-lg-start text-muted"
      >
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div
            style={{
              color: "rgb(70,70,70)",
              maxWidth: "80%",
              margin: "auto",
              fontSize: "10pt",
            }}
          >
            Diese Website wird durch reCAPTCHA geschützt. Die Google{" "}
            <a
              style={{ color: "rgb(70,70,70)" }}
              href="https://policies.google.com/privacy"
            >
              Nutzungsbedingungen
            </a>{" "}
            und deren{" "}
            <a
              style={{ color: "rgb(70,70,70)" }}
              href="https://policies.google.com/terms"
            >
              Datenschutzbestimmungen
            </a>{" "}
            gelten.
          </div>
        </section>

        <section className="">
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-4 marginB">
                <a
                  href="https://laureen-spricht.de"
                  className="text-reset"
                >
                  <h6 className="text-uppercase fw-bold mb-4">
                    {/* <MDBIcon
                      color="secondary"
                      icon="microphone-alt"
                      className="me-3"
                    /> */}
                    {/* <img src={greyLogo} className="me-3" style={{height: "16px"}}/> */}
                    Laureen Spricht
                  </h6>
                </a>
                <a
                  href="https://www.instagram.com/erzaehlmalwas/"
                  className="text-reset"
                >
                  <MDBIcon
                    color="secondary"
                    fab
                    icon="instagram"
                    className="me-3"
                  />
                  Instagram
                </a>
              </MDBCol>

              <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-4 marginB">
                <h6 className="text-uppercase fw-bold mb-4">Seiteninhalte</h6>
                <p>
                  <a
                    href={atHome ? "#" : "/#demos?"}
                    className="text-reset"
                    onClick={(e) => {
                      if (atHome) {
                         e.preventDefault();
                        scrollTo("demos");
                      }
                    }}
                  >
                    Demoübersicht
                  </a>
                </p>
                <p>
                  <a
                    href={atHome ? "#" : "/#ueberMich?"}
                    className="text-reset"
                    onClick={(e) => {
                      if (atHome) {
                         e.preventDefault();
                        scrollTo("ueberMich");
                      }
                    }}
                  >
                    Informationen
                  </a>
                </p>
                <p style={{ margin: 0 }}>
                  <a
                    href={atHome ? "#" : "/#kontakt?"}
                    className="text-reset"
                    onClick={(e) => {
                      if (atHome) {
                        e.preventDefault();
                        scrollTo("kontakt");
                      }
                    }}
                  >
                    Kontakt
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-4 marginB">
                <h6 className="text-uppercase fw-bold mb-4">Nützliche Links</h6>
                <p>
                  <a href="/impressum" className="text-reset">
                    Impressum
                  </a>
                </p>
                <p>
                  <a href="/datenschutz" className="text-reset">
                    Datenschutz
                  </a>
                </p>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    onClick={() => {
                      window.CookieConsentApi.showSettings(0);
                    }}
                  >
                    Cookie Einstellungen
                  </a>
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div
          className="text-center p-4 footer-bottom"
          style={{ backgroundColor: "var(--fifth-color)" }}
        >
          <a>
            © {year === 2023 ? "" : "2023 - "}
            {year} Copyright:
          </a>
          <a style={{ marginLeft: "1em", fontWeight: "500" }}>
            Laureen Matthes
          </a>
        </div>
      </MDBFooter>
    </Reveal>
  );
}

export default Footer;
