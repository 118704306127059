/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import "../../styling/transition.css";
import "../../styling/compLoader.css";

/**
 * @param {*} props defines Routing Function
 * @return {*}
 */
function LoaderCP(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, props.timeout);
  });

  return (
    <>
      {show && (
        <div
          className={`Container-Wrapper-Desktop`}
          style={{
            height: "calc(100vh - env(safe-area-inset-bottom))",
            width: "100%",
          }}
        >
          <Row className="comp-Loader">

            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 703.4 841.2"
              style={{ enableBackground: "new 0 0 703.4 841.2" }}
              width="703.4000244140625"
              height="841.2000122070312"
            >
              <g id="Ebene_1" class="st0">
                <g class="st1">
                  <defs>
                    <rect
                      id="SVGID_1_"
                      x="947.4"
                      y="-28.1"
                      width="781.4"
                      height="684.5"
                      class="svg-elem-1"
                    ></rect>
                  </defs>
                  <clipPath id="SVGID_00000174596391049384266890000012478422118307983285_">
                    <use style={{ overflow: "visible" }}></use>
                  </clipPath>
                  <g
                    style={{
                      clipPath:
                        "url(#SVGID_00000174596391049384266890000012478422118307983285_)",
                    }}
                  >
                    <image
                      style={{ overflow: "visible" }}
                      width="1792"
                      height="1024"
                      transform="matrix(1 0 0 1 431.5227 -218.5979)"
                    ></image>
                  </g>
                </g>
              </g>
              <g id="Ebene_3" class="st0"></g>
              <g id="Ebene_2" class="st0"></g>
              <g id="Ebene_4" class="st0"></g>
              <g id="Ebene_6" class="st0"></g>
              <g id="Ebene_5" class="st0">
                <g
                  id="JsENHv_00000047751033228215618070000003179047257168487053_"
                  class="st1"
                >
                  <image
                    style={{ overflow: "visible" }}
                    width="1024"
                    height="1024"
                    id="JsENHv"
                    transform="matrix(1 0 0 1 -242.4098 -97.2692)"
                  ></image>
                </g>
                <line
                  class="st20 svg-elem-2"
                  x1="269.6"
                  y1="364.4"
                  x2="428.2"
                  y2="262.3"
                ></line>
                <line
                  class="st20 svg-elem-3"
                  x1="159.3"
                  y1="368.9"
                  x2="-29.1"
                  y2="359.8"
                ></line>
                <line
                  class="st20 svg-elem-4"
                  x1="170.4"
                  y1="414.7"
                  x2="5.4"
                  y2="464.7"
                ></line>
                <line
                  class="st20 svg-elem-5"
                  x1="459.9"
                  y1="339.4"
                  x2="294.8"
                  y2="389.4"
                ></line>
                <line
                  class="st20 svg-elem-6"
                  x1="349.6"
                  y1="499.5"
                  x2="279.2"
                  y2="413.9"
                ></line>
                <line
                  class="st20 svg-elem-7"
                  x1="145.6"
                  y1="560.8"
                  x2="215.9"
                  y2="425.4"
                ></line>
              </g>
              <g id="Cat_x5F_Mic"></g>
              <g id="Ebene_8"></g>
              <g id="Ebene_9">
                <path
                  class="st19 svg-elem-8"
                  d="M549.3,834.5H154.1C72.1,834.5,5.6,768,5.6,686V155.2c0-82,66.5-148.5,148.5-148.5h395.2
		c82,0,148.5,66.5,148.5,148.5V686C697.8,768,631.3,834.5,549.3,834.5z"
                ></path>
                <g id="Inside_00000036951708286438925660000014634922381263244731_">
                  <path
                    class="st6 svg-elem-9"
                    d="M477.3,761.6H227.6c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h249.8c6.6,0,12,5.4,12,12l0,0
			C489.3,756.2,483.9,761.6,477.3,761.6z"
                  ></path>
                  <rect
                    x="340.4"
                    y="645.1"
                    class="st6 svg-elem-10"
                    width="23"
                    height="97"
                  ></rect>
                  <g>
                    <path
                      class="st6 svg-elem-11"
                      d="M466.5,230.7h-73.4c-7,0-12.7,5.7-12.7,12.7v0c0,7,5.7,12.7,12.7,12.7h73.4c7,0,12.7-5.7,12.7-12.7v0
				C479.2,236.5,473.5,230.7,466.5,230.7z"
                    ></path>
                    <path
                      class="st6 svg-elem-12"
                      d="M466.5,294.9h-73.4c-7,0-12.7,5.7-12.7,12.7v0c0,7,5.7,12.7,12.7,12.7h73.4c7,0,12.7-5.7,12.7-12.7v0
				C479.2,300.6,473.5,294.9,466.5,294.9z"
                    ></path>
                    <path
                      class="st6 svg-elem-13"
                      d="M466.5,359.1h-73.4c-7,0-12.7,5.7-12.7,12.7l0,0c0,7,5.7,12.7,12.7,12.7h73.4c7,0,12.7-5.7,12.7-12.7l0,0
				C479.2,364.8,473.5,359.1,466.5,359.1z"
                    ></path>
                    <path
                      class="st6 svg-elem-14"
                      d="M466.5,423.2h-73.4c-7,0-12.7,5.7-12.7,12.7v0c0,7,5.7,12.7,12.7,12.7h73.4c7,0,12.7-5.7,12.7-12.7v0
				C479.2,428.9,473.5,423.2,466.5,423.2z"
                    ></path>
                  </g>
                  <g>
                    <path
                      class="st6 svg-elem-15"
                      d="M308.2,230.7h-73.4c-7,0-12.7,5.7-12.7,12.7v0c0,7,5.7,12.7,12.7,12.7h73.4c7,0,12.7-5.7,12.7-12.7v0
				C320.9,236.5,315.2,230.7,308.2,230.7z"
                    ></path>
                    <path
                      class="st6 svg-elem-16"
                      d="M308.2,294.9h-73.4c-7,0-12.7,5.7-12.7,12.7v0c0,7,5.7,12.7,12.7,12.7h73.4c7,0,12.7-5.7,12.7-12.7v0
				C320.9,300.6,315.2,294.9,308.2,294.9z"
                    ></path>
                    <path
                      class="st6 svg-elem-17"
                      d="M308.2,384.5h-73.4c-7,0-12.7-5.7-12.7-12.7l0,0c0-7,5.7-12.7,12.7-12.7h73.4c7,0,12.7,5.7,12.7,12.7l0,0
				C320.9,378.7,315.2,384.5,308.2,384.5z"
                    ></path>
                    <path
                      class="st6 svg-elem-18"
                      d="M308.2,448.6h-73.4c-7,0-12.7-5.7-12.7-12.7v0c0-7,5.7-12.7,12.7-12.7h73.4c7,0,12.7,5.7,12.7,12.7v0
				C320.9,442.9,315.2,448.6,308.2,448.6z"
                    ></path>
                  </g>
                </g>
                <g id="Lines_00000141449674963887577490000001859855823592181136_">
                  <g>
                    <path
                      class="st26 svg-elem-19"
                      d="M162,350.5L162,350.5c-33.9,8.8-65.3,25.5-91.5,48.7l-0.8,0.7"
                    ></path>
                    <path
                      class="st26 svg-elem-20"
                      d="M162,312.9l-0.7-0.2c-32.5-6.9-66.5-0.3-94,18.4l0,0"
                    ></path>
                  </g>
                  <g>
                    <path
                      class="st26 svg-elem-21"
                      d="M539.2,350.5L539.2,350.5c33.9,8.8,65.3,25.5,91.5,48.7l0.8,0.7"
                    ></path>
                    <path
                      class="st26 svg-elem-22"
                      d="M539.2,312.9l0.7-0.2c32.5-6.9,66.5-0.3,94,18.4l0,0"
                    ></path>
                  </g>
                  <path
                    class="st26 svg-elem-23"
                    d="M475.4,279.1l12.2-28.2c15-36.2,20.9-84.7,18.3-123.9l0,0c-0.4-6.8-6.2-11.9-13-11.6l-9,0.4
			c-40.5,2-71,13.2-106.1,33.4l-10.7,6.2"
                  ></path>
                  <path
                    class="st26 svg-elem-24"
                    d="M225.2,267l-6.7-16.1c-15-36.2-20.9-84.7-18.3-123.9l0,0c0.4-6.8,6.2-11.9,13-11.6l9,0.4
			c40.5,2,71,13.2,106.1,33.4l9.4,5.4"
                  ></path>
                  <path
                    class="st26 svg-elem-25"
                    d="M352.4,577h-3.5c-67.6,0-123-55.4-123-123v-175c0-67.6,55.4-123,123-123h3.5c67.6,0,123,55.4,123,123v175
			C475.4,521.7,420,577,352.4,577z"
                  ></path>
                  <path
                    class="st26 svg-elem-26"
                    d="M539.2,414.4v38.9c0,103.7-84.9,188.6-188.6,188.6h0C246.9,641.8,162,557,162,453.3v-38.9"
                  ></path>
                </g>
              </g>
            </svg>
          </Row>
        </div>
      )}
    </>
  );
}

export default LoaderCP;
