import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { CustomAppRouter } from "./AppRouter";
import App from './App';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
<CustomAppRouter>
    <App />
  </CustomAppRouter>
);

