import React, { useEffect } from 'react'
import { HiArrowUp } from 'react-icons/hi'
import { Button } from 'react-bootstrap'
import '../../styling/button.css'

export function ButtonToTop() {
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('scrollend', handleScroll)
    }, [])

    const handleScroll = () => {
        var b = document.getElementById('backToTOpButton')
        var s = document.getElementById('backToTOpButton').style
        if (window.scrollY > 300) {
            b.disabled = false
            s.display = 'visible'
            s.transition = 'visibility 0.2s linear, opacity 0.2s linear'
            s.opacity = 1
            s.visibility = 1
        } else {
            s.opacity = 0
            s.visibility = 0
            setTimeout(() => {
                b.disabled = true
                s.display = 'hidden'
            }, 0)
        }
    }

    return (
        <>
            <Button
                aria-label="Gehe nach oben"
                id="backToTOpButton"
                variant="light"
                size="sm"
                style={{ visibility: '0', display: 'hidden', opacity: '0' }}
                onClick={() => {
                    window.scrollTo({ top: -50, behavior: 'smooth' })
                }}
                className="backToTopButton"
            >
                <HiArrowUp className="hiArrowUp" size={40} style={{ color: 'rgba(0,0,0,0.6)' }} />
            </Button>

            {/* <button>^</button> */}
        </>
    )
}
