import { useLayoutEffect, useState } from 'react'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import React from 'react'

export const history = createBrowserHistory()

export const CustomAppRouter = ({ ...props }) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    })

    useLayoutEffect(() => history.listen(setState), []) // History

    return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />
}
